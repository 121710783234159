@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
}

body {
    background-color: #e1e8eb;
}

.widget {
    @apply border;
}

.number-stat {
    @apply  bg-blue-900 text-gray-50 text-center p-4;
}

.date-container {
    label {
        margin-right: 0.25rem;
    }

    input[type=date] {
        @apply py-1 px-4 border;
    }

    button {
        @apply pt-1 pb-2 text-white bg-blue-800 block px-4;
    }
}

.map-container {

    #malmap {
        path {
            fill: #ca8830;
            stroke-width: 0.5;
            stroke: rgba(0, 0, 0, 0.5);
        }

        path:hover {
            fill: rgb(13, 39, 124);
            cursor: pointer;
        }
    }
}

#content > .container {
    padding-top: 6.5rem;
}

.dataTable-table {
    th, &>tfoot>tr>th, &>thead>tr>th {
        text-align: left;
    }
    &>tbody>tr {
        &>td, &>th {
            text-align: right;
        }

        td:first-child {
            font-weight: bold;
            text-align: left;
        }

        td {
            border-bottom: 1px solid #e2e2e2;
        }

        td {
            border-bottom: 1px solid #e2e2e2;
        }
    }

    tbody tr:nth-child(odd) {
        td {
            background-color: eff1f5;
        }
    }
}
.dataTable-top,
.dataTable-bottom {
    display: none!important;
}

@media (min-width: 768px) {
    #content > .container {
        padding-top: 4.5rem;
    }
}
@media (min-width: 1280px) {
    .container {
        width: 1240px;
    }
}